<template>
  <section>
    <div
      class="headbanner"
      :style="`background-image:url(${bannerUrl});`"
    ></div>
    <v-container class="container-box boxspace">
      <div class="detailspackage">
        <h4>premium jobs</h4>
        <p>
          Take advantage of our extensive offers for effortless job posting
          services. Our team of human resources specialists carefully drafts and
          polishes job openings in order to d raw in the best candidates. Our
          media specialists strategize the release plan in conjunction with your
          chosen advertising agency. Our knowledgeable IT specialists create
          personalized link blocks that prominently display your company's
          identity and open positions on our homepage in the meantime. A
          customized page with the specifics about the position and the
          organization profile is also m ade. With the credentials you've been
          given, you may easily streamline your recruitment process by g aining
          fast access to a well-organized collection of applications.
        </p>
        <div class="tablecontain">
          <table>
            <thead>
              <tr class="header-row">
                <th colspan="4">PREMIUM JOBS CHARGES</th>
              </tr>
              <tr>
                <th>No. of Jobs</th>
                <th>7 Days Display (NRS)</th>
                <th>15 Days Display (NRS)</th>
                <th>30 Days Display (NRS)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>1700</td>
                <td>2200</td>
                <td>3000</td>
              </tr>
              <tr>
                <td>2</td>
                <td>3000</td>
                <td>3700</td>
                <td>5000</td>
              </tr>
              <tr>
                <td>3</td>
                <td>3500</td>
                <td>4500</td>
                <td>6500</td>
              </tr>
              <tr>
                <td>4</td>
                <td>4500</td>
                <td>6000</td>
                <td>8000</td>
              </tr>
              <tr>
                <td>5 & Above</td>
                <td>5500</td>
                <td>8000</td>
                <td>10000</td>
              </tr>
              <tr>
                <td colspan="3">
                  <div>
                    <span
                      >All Time Membership, Un-Limited Job Posting For One
                      Year.</span
                    >
                  </div>
                </td>
                <td>
                  <div><strong>Rs.25000</strong></div>
                </td>
              </tr>
            </tbody>
          </table>
          <p>Note: Additional 13% vat is applicable.</p>
          <div class="features">
            <h6 class="titless">Why Premium job?</h6>
            <div class="pillsfeature">
              <p>Job Alert to registered candidate</p>
              <p>Priority in SEO</p>
              <p>Simplified application shortlisting features</p>
              <p>Branding space for employers</p>
              <p>Display vacancy in social media of Necojobs</p>
              <p>Job description and job specification guidelines</p>
            </div>
          </div>
        </div>
      </div>

      <div class="detailspackage">
        <h4>Standard Jobs</h4>
        <p>
          Improve your hiring process with our customized services. Our
          knowledgeable IT professionals create unique link blocks that smoothly
          incorporate the branding of your company and open positions into the
          section dedicated to standard jobs on our homepage. We can create
          customized sites that highlight the characteristics of a job opening
          by including your company's profile, logo, and pertinent information.
          Use the credentials you have been given to access the gathered
          applications quickly and in an orderly manner. Allow us to optimize
          your hiring procedure with professionally crafted solutions.
        </p>
        <div class="tablecontain">
          <table>
            <thead>
              <tr class="header-row">
                <th colspan="4">Standard JOBS CHARGES</th>
              </tr>
              <tr>
                <th>No. of Jobs</th>
                <th>7 Days Display (NRS)</th>
                <th>15 Days Display (NRS)</th>
                <th>30 Days Display (NRS)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>1500</td>
                <td>1750</td>
                <td>2000</td>
              </tr>
              <tr>
                <td>2</td>
                <td>2800</td>
                <td>3200</td>
                <td>3700</td>
              </tr>
              <tr>
                <td>3</td>
                <td>4000</td>
                <td>4300</td>
                <td>5000</td>
              </tr>
              <tr>
                <td>4</td>
                <td>4200</td>
                <td>5500</td>
                <td>7000</td>
              </tr>
              <tr>
                <td>5 & Above</td>
                <td>4800</td>
                <td>7000</td>
                <td>8500</td>
              </tr>
              <tr>
                <td colspan="3">
                  <div>
                    <span
                      >All Time Membership, Un-Limited Job Posting For One
                      Year.</span
                    >
                  </div>
                </td>
                <td>
                  <div><strong>Rs.20000</strong></div>
                </td>
              </tr>
            </tbody>
          </table>
          <p>Note: Additional 13% vat is applicable.</p>
          <div class="features">
            <h6 class="titless">Why Standard jobs?</h6>
            <div class="pillsfeature">
              <p>Job Alert to registered candidate</p>
              <p>Branding space for employers</p>
              <p>Simplified application shortlisting features</p>
              <p>Display vacancy in social media of Necojobs</p>
              <p>Job description and job specification guidelines</p>
            </div>
          </div>
        </div>
      </div>

      <div class="detailspackage">
        <h4>Features Jobs</h4>
        <p>
          Recruit top talent with our Featured Posting service, which places
          your job prominently on our homepage alongside other valuable
          postings, ensuring awareness to our 5000+ daily visitors. Discerning
          job seekers favor featured listings, paying close attention to vacancy
          specifics and applying appropriately. Stand out from the crowd with
          Featured Postings, which appear first in category searches on
          necojobs.com.np . With the provided credentials, you may easily have
          rapid access to gathered applications, expediting your recruitment
          process
        </p>
        <div class="tablecontain">
          <table>
            <thead>
              <tr class="header-row">
                <th colspan="4">Feature JOBS CHARGES</th>
              </tr>
              <tr>
                <th>No. of Jobs</th>
                <th>7 Days Display (NRS)</th>
                <th>15 Days Display (NRS)</th>
                <th>30 Days Display (NRS)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>700</td>
                <td>800</td>
                <td>1000</td>
              </tr>
              <tr>
                <td>2</td>
                <td>1300</td>
                <td>1500</td>
                <td>1800</td>
              </tr>
              <tr>
                <td>3</td>
                <td>1800</td>
                <td>2300</td>
                <td>2800</td>
              </tr>
              <tr>
                <td>4</td>
                <td>2300</td>
                <td>2800</td>
                <td>3300</td>
              </tr>
              <tr>
                <td>5 & Above</td>
                <td>2800</td>
                <td>3400</td>
                <td>4000</td>
              </tr>
              <tr>
                <td colspan="3">
                  <div>
                    <span
                      >All Time Membership, Un-Limited Job Posting For One
                      Year.</span
                    >
                  </div>
                </td>
                <td>
                  <div><strong>Rs.20000</strong></div>
                </td>
              </tr>
            </tbody>
          </table>
          <p>Note: Additional 13% vat is applicable.</p>
          <div class="features">
            <h6 class="titless">Why Feature jobs?</h6>
            <div class="pillsfeature">
              <p>Visible on Home page</p>
              <p>Cost Effective</p>
              <p>job description and job specification guideline</p>
              <p>Simplified application shortlisting features</p>
              <p>Priority on job search</p>
            </div>
          </div>
        </div>
      </div>
    </v-container>
    <div class="packagehightlight">
      <div class="innerboxss">
        <div>
          <img src="@/assets/postajob/1.png" alt="" />
          <h6>Nepal's</h6>
          <p>#1 Job Portal</p>
        </div>
        <div>
          <img src="@/assets/postajob/2.png" alt="" />
          <h6>5000+</h6>
          <p>Employers</p>
        </div>
        <div>
          <img src="@/assets/postajob/3.png" alt="" />
          <h6>5000+</h6>
          <p>Daily traffic</p>
        </div>
        <div>
          <img src="@/assets/postajob/4.png" alt="" />
          <h6>20,000+</h6>
          <p>social media followers</p>
        </div>
        <div>
          <img src="@/assets/postajob/5.png" alt="" />
          <h6>100,000+</h6>
          <p>jobseekers</p>
        </div>
      </div>
    </div>
    <div class="packagehightlight">
      <p>buying option</p>
      <div class="innerboxss1">
        <img src="@/assets/postajob/6.png" alt="" />
        <img src="@/assets/postajob/7.png" alt="" />
        <img src="@/assets/postajob/8.png" alt="" />
        <img src="@/assets/postajob/9.png" alt="" />
        <img src="@/assets/postajob/QR.png" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Post a job",
  data: () => ({
    bannerUrl: require("@/assets/placeholder.jpg"),
  }),
  methods: {
    ...mapActions(["getAssets","getSingleMetaPage"]),
  },
  computed: {
    ...mapGetters(["allAssets","allMeta"]),
  },
  metaInfo() {
    return {
      title: this.allMeta && this.allMeta.title,
      meta: [
        {
          name: "description",
          content: this.allMeta && this.allMeta.description,
        },
        {
          name: "keywords",
          content: this.allMeta && this.allMeta.keywords,
        },
      ],
    };
  },
  async created() {
    await this.getAssets({
      title: "PostAJob",
      connectedTo: "page",
    });
    this.bannerUrl = this.allAssets[0].image;
    await this.getSingleMetaPage("postajob");
  },
  
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.headbanner {
  background-size: cover;
  height: 450px;
  width: 100%;
  background-position-x: center;
}

.boxspace {
  padding: 40px 0;
  .detailspackage {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 15px;
    h4 {
      font-size: 28px;
      text-transform: uppercase;
      text-align: left;
    }
    p {
      font-size: 16px;
    }
    .tablecontain {
      p {
        text-align: right;
        font-size: 14px;
        padding: 5px 0;
      }

      .features {
        .titless {
          font-size: 18px;
          text-transform: uppercase;
          background-color: $tagcolor;
          padding: 5px 15px;
          border-radius: 40px;
          color: #fff;
          width: 30%;
          text-align: center;
        }
        .pillsfeature {
          padding: 10px 0;
          gap: 10px;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          p {
            border: 1px solid $tagcolor;
            text-align: left;
            padding: 10px 15px;
            border-radius: 40px;
            font-size: 14px;
            text-transform: capitalize;
          }
          ::before {
            display: inline-block;
            content: "";
            -webkit-border-radius: 0.375rem;
            border-radius: 0.375rem;
            height: 10px;
            width: 10px;
            margin-right: 0.5rem;
            background-color: $tagcolor;
          }
        }
      }

      table {
        border-collapse: collapse;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
      th,
      td {
        padding: 10px;
        text-align: center;
        border: 1px solid #ddd;
      }
      th {
        background-color: $tagcolor;
        color: #fff;
      }
      .header-row {
        background-color: $tagcolor;
        font-size: 20px;
        text-transform: uppercase;
        text-align: center;
        background-color: $tagcolor;
        padding: 5px 0;
        color: #fff;
      }
    }
  }
}

.packagehightlight {
  border-top: 1px solid gray;
  padding: 20px 0;
  p {
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    padding-bottom: 15px;
  }

  .innerboxss {
    max-width: 960px;
    margin: 0 auto;
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    justify-content: center;
    align-items: center;
    text-align: center;

    h6 {
      font-weight: bold;
      font-size: 16px;
    }
    p {
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      text-transform: capitalize;
    }
    img {
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
  }

  .innerboxss1 {
    max-width: 800px;
    margin: 0 auto;
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    justify-content: center;
    align-items: center;
    text-align: center;

    h6 {
      font-weight: bold;
      font-size: 16px;
    }
    p {
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      text-transform: capitalize;
    }
    img {
      width: 130px;
      height: 130px;
      object-fit: contain;
    }
  }
}

// tr:nth-child(even) {
//   border: 1px solid red;
// }

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > th
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border: inherit;
}

@media screen and (max-width: 992px) {
  .detailspackage {
    padding: 0 20px;
  }

  .headbanner {
    background-size: cover;
    height: 250px;
    width: 100%;
  }

  .titless {
    width: 100% !important;
  }
  .packagehightlight {
    padding: 20px;
    .innerboxss {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      img {
        width: 80px;
        height: 80px;
        object-fit: contain;
      }
    }
    .innerboxss1 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      img {
        width: 100px;
        height: 100px;
        object-fit: contain;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .headbanner {
    background-size: cover;
    height: 15vh;
    width: 100%;
    background-position-x: center;
  }
  .tablecontain {
    padding: 30px 0px;
    .pillsfeature {
      p {
        font-size: 12px !important;
      }
    }
  }
}
</style>
